import { useSelector } from "react-redux";
import FriendsInGridView from "./FriendsInGridView";
import { ACCEPT, BLOCK, REJECT } from "../../Constants";
import { useDispatch } from "react-redux";
import styles from "../../pages/searchGroom/SearchedPeopleToMarryGridView.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import {
  pendingRequestIsAccepted,
  pendingRequestIsRejected,
  pendingRequestIsBlocked,
} from "../../store/reducers/friendsSlice";
import { useState } from "react";
import {
  fetchFriendsOfActiveTab,
  performActionOnRequestAndUpdateRedux,
} from "../../pages/friends/FriendsLoader";
import { Loader } from "../UI/ErrorModal";
import NoRecordView from "./NoRecordView";
import { textFor } from "../../util/languages";
function PendingRequests() {
  const data = useSelector((state) => state.friends.pendingRequests);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState("");
  let hideDialog = null;
  //-------------------------------------------------------------------
  async function updateRequestAndRedux(
    personToViewDetail,
    reqAction,
    reduxAction
  ) {
    setIsLoading("1");
    try {
      await performActionOnRequestAndUpdateRedux(
        personToViewDetail,
        reqAction,
        reduxAction,
        dispatch
      );
    } finally {
      setIsLoading("");
      hideDialog("");
    }
  }

  function getViewForRemoveButton(personToViewDetail) {
    return (
      <div className={commonStyles.nearImageMultiRowButtonWrapper}>
        <div className={commonStyles.nearImageBtnWrapper}>
          <button
            className={commonStyles.greenButton}
            onClick={() =>
              updateRequestAndRedux(
                personToViewDetail,
                ACCEPT,
                pendingRequestIsAccepted
              )
            }
            type="button"
          >
            {textFor("accept")}
          </button>
        </div>
        <div
          className={commonStyles.nearImageBtnWrapper}
          style={{ gap: "10px" }}
        >
          <button
            className={commonStyles.magentaButton}
            style={{ paddingLeft: "7px", paddingRight: "7px" }}
            onClick={() =>
              updateRequestAndRedux(
                personToViewDetail,
                REJECT,
                pendingRequestIsRejected
              )
            }
            type="button"
          >
            {textFor("reject")}
          </button>
          <button
            className={commonStyles.brownButton}
            style={{ paddingLeft: "7px", paddingRight: "7px" }}
            onClick={() =>
              updateRequestAndRedux(
                personToViewDetail,
                BLOCK,
                pendingRequestIsBlocked
              )
            }
            type="button"
          >
            {textFor("block")}
          </button>
        </div>
      </div>
    );
  }

  async function loadMoreClicked(e) {
    setIsLoading("1");
    await fetchFriendsOfActiveTab(
      dispatch,
      data.reqId,
      parseInt(data.pageNumber) + 1
    );
    setIsLoading("");
  }
  function showLoaderIfNeeds() {
    if (isLoading !== "") {
      console.log("Loader is active...");
      return <Loader />;
    } else {
      console.log("Loader is off.");
      return "";
    }
  }
  function setMethodToHidePersonDetailDialog(methodToHideDialog) {
    hideDialog = methodToHideDialog;
  }
  return data.elements.length > 0 ? (
    <>
      {showLoaderIfNeeds()}
      <FriendsInGridView
        data={data}
        loadMoreClicked={loadMoreClicked}
        getViewForTopRightArea={getViewForRemoveButton}
        setMethodToHidePersonDetailDialog={setMethodToHidePersonDetailDialog}
      />
    </>
  ) : (
    <NoRecordView />
  );
}

export default PendingRequests;
