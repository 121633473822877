import { useSelector } from "react-redux";
import FriendsInGridView from "./FriendsInGridView";
import { ACCEPT, BLOCK, DELETE, RESEND } from "../../Constants";
import { useDispatch } from "react-redux";
import styles from "../../pages/searchGroom/SearchedPeopleToMarryGridView.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import {
  fetchWaitingRequestsSuccess,
  rejectedRequestIsAccepted,
  rejectedRequestIsBlocked,
  rejectedRequestIsDeleted,
  rejectedRequestIsResent,
} from "../../store/reducers/friendsSlice";
import { useState } from "react";
import {
  fetchFriendsOfActiveTab,
  performActionOnRequestAndUpdateRedux,
} from "../../pages/friends/FriendsLoader";
import { Loader } from "../UI/ErrorModal";
import { textFor } from "../../util/languages";
import NoRecordView from "./NoRecordView";
function RejectedRequests() {
  const data = useSelector((state) => state.friends.rejectedRequests);
  const selfMemberId = localStorage.getItem("selfMemberId");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState("");
  let hideDialog = null;
  //-------------------------------------------------------------------
  async function deleteFriendReq(personToViewDetail) {
    if (window.confirm(textFor("areYouSure"))) {
      console.log("to delete: ", personToViewDetail);
      await updateRequestAndRedux(
        personToViewDetail,
        DELETE,
        rejectedRequestIsDeleted
      );
    }
  }
  async function acceptRejectedReq(personToViewDetail) {
    await updateRequestAndRedux(
      personToViewDetail,
      ACCEPT,
      rejectedRequestIsAccepted
    );
  }
  async function blockRejectedReq(personToViewDetail) {
    await updateRequestAndRedux(
      personToViewDetail,
      BLOCK,
      rejectedRequestIsBlocked
    );
  }
  async function resendFriendReq(personToViewDetail) {
    await updateRequestAndRedux(
      personToViewDetail,
      RESEND,
      rejectedRequestIsResent
    );
  }
  async function updateRequestAndRedux(
    personToViewDetail,
    reqAction,
    reduxAction
  ) {
    setIsLoading("1");
    try {
      await performActionOnRequestAndUpdateRedux(
        personToViewDetail,
        reqAction,
        reduxAction,
        dispatch
      );
    } finally {
      setIsLoading("");
      hideDialog("");
    }
  }

  function getActionButtonsForRequestTheySent(personToViewDetail) {
    return (
      <div className={commonStyles.nearImageMultiRowButtonWrapper}>
        <div className={commonStyles.nearImageBtnWrapper}>
          <button
            className={commonStyles.greenButton}
            onClick={() => acceptRejectedReq(personToViewDetail)}
            type="button"
          >
            {textFor("accept")}
          </button>
        </div>
        <div className={commonStyles.nearImageBtnWrapper}>
          <button
            className={commonStyles.brownButton}
            onClick={() => blockRejectedReq(personToViewDetail)}
            type="button"
          >
            {textFor("block")}
          </button>
        </div>
      </div>
    );
  }
  function getActionButtonsForRequestISent(personToViewDetail) {
    return (
      <div className={commonStyles.nearImageMultiRowButtonWrapper}>
        <div className={commonStyles.nearImageBtnWrapper}>
          <button
            className={commonStyles.orangeButton}
            onClick={() => resendFriendReq(personToViewDetail)}
            type="button"
          >
            {textFor("resend")}
          </button>
        </div>
        <div className={commonStyles.nearImageBtnWrapper}>
          <button
            className={commonStyles.brownButton}
            onClick={() => deleteFriendReq(personToViewDetail)}
            type="button"
          >
            {textFor("delete")}
          </button>
        </div>
      </div>
    );
  }
  function getViewForRemoveButton(personToViewDetail) {
    return personToViewDetail.targetMemberId == selfMemberId
      ? getActionButtonsForRequestTheySent(personToViewDetail)
      : getActionButtonsForRequestISent(personToViewDetail);
  }

  async function loadMoreClicked(e) {
    setIsLoading("1");
    await fetchFriendsOfActiveTab(
      dispatch,
      data.reqId,
      parseInt(data.pageNumber) + 1
    );
    setIsLoading("");
  }
  function showLoaderIfNeeds() {
    if (isLoading !== "") {
      console.log("Loader is active...");
      return <Loader />;
    } else {
      console.log("Loader is off.");
      return "";
    }
  }
  function setMethodToHidePersonDetailDialog(methodToHideDialog) {
    hideDialog = methodToHideDialog;
  }
  return data.elements.length > 0 ? (
    <>
      {showLoaderIfNeeds()}
      <FriendsInGridView
        data={data}
        loadMoreClicked={loadMoreClicked}
        getViewForTopRightArea={getViewForRemoveButton}
        setMethodToHidePersonDetailDialog={setMethodToHidePersonDetailDialog}
        getViewForPreviewTopRightIcon={(m) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              {m.targetMemberId == selfMemberId ? (
                <i
                  className="fa fa-reply"
                  style={{ color: "black" }}
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  className="fa fa-share"
                  style={{ color: "black" }}
                  aria-hidden="true"
                ></i>
              )}
            </div>
          );
        }}
      />
    </>
  ) : (
    <NoRecordView />
  );
}

export default RejectedRequests;
