import commonStyles from "../commonStyles.module.scss";
import { textFor } from "../util/languages";
function TermsAndConditions() {
  return (
    <div
      className={commonStyles.scrollableContainer}
      style={{ height: "75vh" }}
    >
      <div>
        <h5
          className={commonStyles.centeredMessage}
          style={{ textDecoration: "underline" }}
        >
          {textFor("termsAndConditions")}
          <br /> {textFor("termsUpdatedOn")}
        </h5>
        <ol>
          <li className={commonStyles.note1} key="1">
            {textFor("introduction")}
            <ul>
              <li
                key="1.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("theseTermsGovernsYourUse")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="2">
            {textFor("eligibilityForUsingThisSite")}
            <ul>
              <li
                key="2.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("age18AndShouldKnowLanguages")}
              </li>
              <li
                key="2.2"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("legallyCompetentForMaritalBinding")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="3">
            {textFor("accountCredentials")}
            <ul>
              <li
                key="3.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("youMustMaintainConfidentiality")}
              </li>
              <li
                key="3.2"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("notifyUsImmediatelyForUnauthorizedAccess")}
              </li>
              <li
                key="3.3"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weCanSuspendYourAccountBecauseOf:")}
                <ul>
                  <li
                    key="3.3.1"
                    className={`${commonStyles.circleBullet} ${commonStyles.note3}`}
                  >
                    {textFor("objectionableContent")}
                  </li>
                  <li
                    key="3.3.2"
                    className={`${commonStyles.circleBullet} ${commonStyles.note3}`}
                  >
                    {textFor("youCauseAnyProblem")}
                  </li>
                  <li
                    key="3.3.3"
                    className={`${commonStyles.circleBullet} ${commonStyles.note3}`}
                  >
                    {textFor("youEngageInIllegalActivity")}
                  </li>
                </ul>
              </li>
              <li
                key="3.4"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("dontShareYourAccountWithOthers")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="4">
            {textFor("userConduct")}
            <ul>
              <li
                key="4.f1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("provideTruthfulInfo")}
              </li>
              <li
                key="4.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("youWillUseTheSiteForLawfulPurposes")}
              </li>
              <li
                key="4.2"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("youAgreeThatYouWillNever:")}
                <ul>
                  <li
                    key="4.2.1"
                    className={`${commonStyles.circleBullet} ${commonStyles.note3}`}
                  >
                    {textFor("useSiteToTransmitUnlafulMaterial")}
                  </li>
                  <li
                    key="4.2.2"
                    className={`${commonStyles.circleBullet} ${commonStyles.note3}`}
                  >
                    {textFor("useSiteToTransmitCopyrightedInfo")}
                  </li>
                  <li
                    key="4.2.3"
                    className={`${commonStyles.circleBullet} ${commonStyles.note3}`}
                  >
                    {textFor("useSiteToTransmitVirus")}
                  </li>
                  <li
                    key="4.2.4"
                    className={`${commonStyles.circleBullet} ${commonStyles.note3}`}
                  >
                    {textFor("useSiteToDisruptNetworks")}
                  </li>
                  <li
                    key="4.2.5"
                    className={`${commonStyles.circleBullet} ${commonStyles.note3}`}
                  >
                    {textFor("collectOthersPersonalInfoWithoutConsent")}
                  </li>
                  <li
                    key="4.2.6"
                    className={`${commonStyles.circleBullet} ${commonStyles.note3}`}
                  >
                    {textFor("impersonateOrFalselyClaim")}
                  </li>
                  <li
                    key="4.2.7"
                    className={`${commonStyles.circleBullet} ${commonStyles.note3}`}
                  >
                    {textFor("useAutomatedMeansToCopySiteItems")}
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="5">
            {textFor("warrantyDisclaimer")}
            <ul>
              <li
                key="5.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("siteIsProvidedAsIs")}
              </li>
              <li
                key="5.2"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("noWarrantyOfVirus")}
              </li>
              <li
                key="5.3"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("youAckToUseAntivirus")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="6">
            {textFor("limitationOfLiability")}
            <ul>
              <li
                key="6.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weAreNeverLiableForAnyDamage")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="7">
            {textFor("indemnification")}
            <ul>
              <li
                key="7.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("youAgreeToIndemnifyUs")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="8">
            {textFor("informationPrivacy")}
            <ul>
              <li
                key="8.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("referToOurPrivacyPolicy")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="9">
            {textFor("intellectualProperty")}
            <ul>
              <li
                key="9.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("infoOfSiteIsCopyrighted")}
              </li>
              <li
                key="9.2"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("youCantUseThisSitesInfo")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="10">
            {textFor("thirdPartyLinks")}
            <ul>
              <li
                key="10.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weAreNotResponsibleFor3rdPartyPolicies")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="11">
            {textFor("governingLawAndJurisdiction")}
            <ul>
              <li
                key="11.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("termsGovernedByIndianCourts")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="12">
            {textFor("termsAndSiteModifications")}
            <ul>
              <li
                key="12.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weCanUpdateSiteAnytime")}
              </li>
              <li
                key="12.2"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("youUseMeansYouKnowLatestTerms")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="13">
            {textFor("updationRemovalOfInfo")}
            <ul>
              <li
                key="13.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weDontReviewEachDetail")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="14">
            {textFor("suspensionOrTerminationOfAcct")}
            <ul>
              <li
                key="14.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weCanSuspendAcctAnytime")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="15">
            {textFor("dataSecurityAndAccuracy")}
            <ul>
              <li
                key="15.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("youAgreeThatAlwaysSecurityIsNotPossible")}
              </li>
              <li
                key="15.2"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("youShouldBeSureAboutInfoYouAreSharing")}
              </li>
              <li
                key="15.3"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weAreNotResponsibleForOthersInfo")}
              </li>
              <li
                key="15.4"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("youCannotSueThisSite")}
              </li>
              <li
                key="15.5"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("youKnowAllUsersWillAccessYourInfo")}
              </li>
              <li
                key="15.6"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weCantGuaranteeAccuracy")}
              </li>
            </ul>
          </li>
        </ol>
        <hr />
      </div>
    </div>
  );
}

export default TermsAndConditions;
