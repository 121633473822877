import commonStyles from "../../../../../commonStyles.module.scss";
import { doLogout, getAuthToken } from "../../../../../util/auth";
import { BASE_URL } from "../../../../../Constants";
import { json } from "react-router-dom";
import { clearPendingEmail } from "../../../../../store/reducers/pendingEmailSlice";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../../../store/reducers/authSlice";
import { textFor } from "../../../../../util/languages";
import { useState } from "react";
import AlertDialog from "../../../../UI/alertDialog/AlertDialog";
import { Loader } from "../../../../UI/ErrorModal";
const PendingEmail = () => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState("");
  const [showEmailVerification, setShowEmailVerification] = useState("");
  const pendingEmail = useSelector((state) => state.pendingEmail.pendingEmail); // Select the pendingEmail state
  const pendingEmailOtp = useSelector(
    (state) => state.pendingEmail.pendingEmailOtp
  );
  // console.log("In PendingVerificationemail mail is=", pendingEmail);
  const onDelete = async (e) => {
    if (pendingEmail !== "") {
      const confirmed = window.confirm(textFor("areYouSure"));
      if (confirmed) {
        await removePendigEmailFromUserProfile();
        dispatch(clearPendingEmail());
      }
    }
  };

  const removePendigEmailFromUserProfile = async () => {
    try {
      setShowLoader("1");
      const token = getAuthToken();
      const phoneOrEmail = "email";
      let url =
        BASE_URL +
        "/normalUser/newLoginMethod?phoneOrEmail=" +
        encodeURIComponent(phoneOrEmail);
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      // toTestLoader await new Promise((resolve) => setTimeout(resolve, 5000));
      const response = await fetch(url, {
        method: "DELETE",
        headers: headers,
      });
      console.log("response from delete newLoginMethod = ", response);

      if (!response.ok) {
        if (response.status === 403) {
          doLogout(dispatch, authActions);
        } else {
          throw json(
            { message: "Could not delete newLoginMethod." },
            {
              status: 500,
            }
          );
        }
      } else {
        console.log("successfully deleted newLogin method");
      }
    } catch (error) {
      // Handle errors, including 403 Forbidden
      console.error("Error during profile fetch:", error);
    } finally {
      setShowLoader("");
    }
  };

  function showEmailVerificationStepsDialog() {
    setShowEmailVerification("1");
  }
  function hideEmailVerificationStepsDialog() {
    setShowEmailVerification("");
  }
  function getVerificationDialogView() {
    console.log("pendingOtp=" + pendingEmailOtp);
    return (
      <>
        <span>{textFor("yourEmailChangeRequestIsAccepted")}</span>
        <br />
        <span>{textFor("sendOtpOnEmail1")}</span>
        <br />
        <span>{textFor("sendOtpOnEmail2")}</span>
        <br />
        <span>{textFor("sendOtpOnEmail3") + "C " + pendingEmailOtp}</span>
        <br />
        <span>{textFor("sendOtpOnEmail4")}</span>
        <br />
        <span>{textFor("yourEmailIs") + pendingEmail}</span>
      </>
    );
  }
  function ifNeedsBeShowEmailVerifiationStepsDialog() {
    if (showEmailVerification) {
      return (
        <AlertDialog
          onOkClick={hideEmailVerificationStepsDialog}
          onBackdropClicked={hideEmailVerificationStepsDialog}
          title={textFor("verificationSteps")}
          getContent={getVerificationDialogView}
          okButtonText="OK"
        />
      );
    } else {
      return "";
    }
  }

  return (
    <>
      {showLoader && <Loader />}
      {ifNeedsBeShowEmailVerifiationStepsDialog()}
      <div>
        <div id="pendingEmailHeading" className={commonStyles.pendingHeading}>
          {textFor("pendingNewEmail:")}
        </div>
        <p id="pendingEmail" className={commonStyles.pendingField}>
          {pendingEmail}
        </p>
        <div style={{ marginTop: "1vh" }}>
          <button
            id="pendingEmailVerifyButton"
            className={commonStyles.blueButton}
            onClick={showEmailVerificationStepsDialog}
          >
            {textFor("verify")}
          </button>
          <button
            id="pendingEmailDeleteButton"
            className={commonStyles.darkRedButton}
            onClick={onDelete}
          >
            {textFor("delete")}
          </button>
        </div>
      </div>
      <br />
      <hr />
    </>
  );
};
export default PendingEmail;
