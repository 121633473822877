import {
  Await,
  defer,
  json,
  redirect,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import { dummyGetPeople } from "../../util/dummyData";
import { Suspense } from "react";
import ListMembersGridView from "./ListMembersGridView";
import { BASE_URL, MEMBER_DETAILS } from "../../Constants";
import { doLogout, getAuthToken } from "../../util/auth";
import { attachMemberDetailsToList } from "../../store/reducers/savedGroomListsSlice";
import { Loader } from "../../components/UI/ErrorModal";
import { authActions } from "../../store/reducers/authSlice";

function ListMemberLoader() {
  // const pathParam = useParams();

  const { routeLoaderData } = useRouteLoaderData("listMembers");
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Await resolve={routeLoaderData}>
          {(routeLoaderData) => <ListMembersGridView {...routeLoaderData} />}
        </Await>
      </Suspense>
    </>
  );
}
export default ListMemberLoader;
export async function loadListMembers(
  dispatch,
  reduxSavedGroomLists,
  request,
  params
) {
  const parentList = reduxSavedGroomLists.savedGroomLists.find(
    (list) => list.id == params.listId
  );
  // if (!parentList) {
  //   return <>This list is deleted.</>;
  //   // const lastPath = localStorage.getItem("lastPath");
  //   // console.log("lastPath = ", lastPath);
  //   // if (lastPath === "listMembers") {
  //   //   return redirect("/searchGroom");
  //   // } else {
  //   //   return redirect("/dashboard"); //that means user is pressing back
  //   // }
  // } else {
  return defer({
    routeLoaderData: loadSavedListMembers(
      dispatch,
      reduxSavedGroomLists.savedGroomLists,
      request,
      params
    ),
  });
  // }
}

export async function loadSavedListMembers(
  dispatch,
  reduxSavedGroomLists,
  request,
  params
) {
  //we will just ignore request param as it is not useful for us here
  //   console.log("request=", request);
  console.log("In loadSavedListMembers params =", params);
  console.log("reduxSavedGroomLists=", reduxSavedGroomLists);
  //reduxSavedGroomLists is from redux, so don't refetch if id is present
  const parentList = reduxSavedGroomLists.find(
    (list) => list.id == params.listId
  );
  //In redux, in savedGroomList, the memberDetails must be present
  //and the length of it must be equal to the list.membersCount
  //else fetch again
  if (parentList) {
    console.log("found parentListId...");
    if (
      MEMBER_DETAILS in parentList &&
      parentList[MEMBER_DETAILS].length >= parentList.membersCount
    ) {
      console.log("list already fetched");
      return {
        elements: parentList[MEMBER_DETAILS],
        parentListId: params.listId,
        parentListName: parentList.name,
      };
    } else {
      return fetchParentListMemberDetails(parentList, dispatch);
    }
  } else {
    return redirect("/dashboard");
  }
  // return {
  //   elements: dummyGetPeople(),
  //   parentListId: params.listId,
  //   parentListName: parentList.name,
  // };
}
async function fetchParentListMemberDetails(parentList, dispatch) {
  try {
    const token = getAuthToken();
    let url = BASE_URL + "/grooms/listMembers?listId=" + parentList.id;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    console.log(
      "response from fetch during fetchParentListMemberDetails = ",
      response
    );

    if (!response.ok) {
      if (response.status === 403) {
        return doLogout(dispatch, authActions);
      } else {
        throw json({
          message: "Could not fetch details for fetchParentListMemberDetails.",
          status: 500,
        });
      }
    } else {
      const listMemberDetails = await response.json();
      console.log("got response ", listMemberDetails);
      dispatch(
        attachMemberDetailsToList({
          parentListId: parentList.id,
          memberDetails: listMemberDetails,
        })
      );
      return {
        elements: listMemberDetails,
        parentListId: parentList.id,
        parentListName: parentList.name,
      };
    }
  } catch (error) {
    console.log("Error in fetchParentListMemberDetails: ", error);
    return {};
  }
}
