import { textFor } from "./languages";
import { startsWith } from "./viewUtils";

export function isLikeAPhoneNumber(input) {
  if (input.length < 1) {
    return false;
  }

  if (/^\+?\d*$/.test(input)) {
    return true;
  }
  return false;
}
export function isLikeAnEmail(input) {
  return !isLikeAPhoneNumber(input);
}
export function isAPhoneNumber(input) {
  // Regular expression to match "+91" followed by 10 digits
  if (!startsWith(input, "+91")) {
    alert(textFor("phoneMustStartsWith91"));
    return false;
  }

  if (input.length < 13) {
    alert(textFor("phoneLengthMustBe12"));
    return false;
  }
  const regex = /^\+91[0-9]{10}$/;
  const result = regex.test(input);
  if (!result) {
    alert(textFor("invalidValueForPhone"));
  }
  return result;
}
export function isValidOtp(otp) {
  if (otp.length !== 6 || !/^\d*$/.test(otp)) {
    alert(textFor("invalidValueForOtp"));
    return false;
  }

  return true;
}
export function isAnEmail(emailInput) {
  const email = emailInput.toLowerCase();
  if (email.length < 5) {
    alert(textFor("emailLengthMustBeMore"));
    return false; //a@b.c
  }
  if (email.length > 50) {
    alert(textFor("maxLengthIsN"));
    return false;
  }
  const emailParts = email.split("@");
  if (emailParts.length <= 1) {
    alert(textFor("invalidValueForEmail"));
    return false;
  }
  if (emailParts.length > 2) {
    alert(textFor("onlyOneAdAllowedInEmail"));
    return false; //must have only one @, so 2 parts left and right
  }
  const withoutUnderscore = removeAllOccurrences(email, "_");
  const withoutAt = removeAllOccurrences(withoutUnderscore, "@");
  const withoutDot = removeAllOccurrences(withoutAt, "\\.");
  const withoutHyphen = removeAllOccurrences(withoutDot, "-");
  const withAlphaAndDigit = withoutHyphen;
  const regExForAlphaDigit = /^[a-z0-9]+$/;
  if (
    withAlphaAndDigit.length > 0 &&
    !regExForAlphaDigit.test(withAlphaAndDigit)
  ) {
    alert(textFor("OnlyValidCharsAllowedInEmail"));
    return false;
  }

  // const leftPart = emailParts[0];
  const rightPart = emailParts[1];
  const domainParts = rightPart.split(".");
  if (domainParts.length > 2) {
    alert(textFor("inEmailMax2DotAllowedAfterAd"));
    //may be later we will allow multiple dots to support modi@nic.gov.in type mails
    return false; //only one dot allowed
  }

  const validDomainNameRegEx = /^(?!-)[A-Za-z0-9]+(-[A-Za-z0-9]+)*$/;
  if (!validDomainNameRegEx.test(domainParts[0])) {
    alert(textFor("emailSiteNameIsInvalid"));
    return false;
  }

  //only alphabet regex
  const onlyAlphaRegex = /^[A-Za-z]+$/;
  //check topLevelDomain e.g. com, in, org net etc
  if (!onlyAlphaRegex.test(domainParts[1])) {
    alert(textFor("emailSiteNameIsInvalid"));
    return false;
  }

  return true;
}
function removeAllOccurrences(inputString, charToRemove) {
  //use \\ for . + to remove
  const regex = new RegExp(charToRemove, "g"); //g is for global option in regex
  return inputString.replace(regex, "");
}

export function isAValidPassword(input) {
  if (input.length < 7) {
    alert(textFor("invalidValueForPassword"));
    return false;
  }
  return true;
}

export function containsOnlyDigits(str) {
  if (str.length == 0) {
    return true;
  }
  console.log("containsOnlyDigits = " + /^\d+$/.test(str));
  return /^\d+$/.test(str);
}

export function containsDot(str) {
  console.log("containsDot for " + str + "=" + str.includes("."));
  return str.includes(".");
}

export function removeDot(str) {
  return str.replace(/\./g, "");
}
