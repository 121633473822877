import React, { useState } from "react";
import { FaTimes, FaEye, FaEyeSlash } from "react-icons/fa";
import styles from "./Modal.module.scss";
import commonStyles from "../../../commonStyles.module.scss";

const Modal = (props) => {
  const [eyeOn, setEyeOn] = useState("");
  const [textVal, setTextVal] = useState("");
  const onOk = (e) => {
    if (props.userInputValidator(textVal)) {
      props.onOk({
        userInput: textVal,
      });
    }
  };
  function onTextChange(e) {
    setTextVal(e.target.value);
  }
  return (
    <div className={`${styles.modal} ${styles.open}`}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>{props.modalHeading}</h2>
          <button className={styles.closeBtn} onClick={props.onCancel}>
            <FaTimes />
          </button>
        </div>
        <div className={styles.modalBody}>
          <label htmlFor="inputField">{props.userInputTitle}</label>
          <div className={commonStyles.rowFlex}>
            <input
              type={eyeOn === "" ? "password" : "text"}
              id="inputField"
              onChange={onTextChange}
              placeholder={props.placeholder || ""}
            />
            <div
              className={commonStyles.iconWrapper}
              onClick={() => {
                setEyeOn((prevState) => {
                  return prevState === "" ? "1" : "";
                });
              }}
            >
              {eyeOn === "" ? <FaEye /> : <FaEyeSlash />}
            </div>
          </div>
          <br />
          <br />
        </div>
        <div className={styles.modalFooter}>
          <button onClick={onOk}>{props.okButtonText || "OK"}</button>
          <button onClick={props.onCancel}>
            {props.cancelButtonText || "CANCEL"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
