import React from "react";
import { Suspense } from "react";
import { useRouteLoaderData, json, defer, Await } from "react-router-dom";
import { doLogout, getAuthToken } from "../util/auth";
import commonStyles from "../commonStyles.module.scss";
import { BASE_URL } from "../Constants";
import AppSettings from "../components/appSettings/AppSettings";
import { Loader } from "../components/UI/ErrorModal";
import { authActions } from "../store/reducers/authSlice";
import { textFor } from "../util/languages";
function Settings() {
  const { routeLoaderData } = useRouteLoaderData("settings");
  //console.log("routeLoaderData in settings = ", routeLoaderData); //it will be a Promise

  return (
    <>
      <div className={commonStyles.topPanel}>{textFor("settings:")}</div>
      <Suspense fallback={<Loader />}>
        <Await resolve={routeLoaderData}>
          {(routeLoaderData) => (
            <AppSettings registrationDetails={routeLoaderData} />
          )}
        </Await>
      </Suspense>
    </>
  );
}

export default Settings;

export async function loadAppSettings(dispatch) {
  return defer({ routeLoaderData: [] });
}

export async function loadSettings(dispatch) {
  const token = getAuthToken();
  let url = BASE_URL + "/normalUser/registrationDetail";
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    console.log("response from fetch during loadSettings = ", response);

    if (!response.ok) {
      if (response.status === 403) {
        return doLogout(dispatch, authActions);
      } else {
        throw json(
          { message: "Could not fetch details for selected event." },
          {
            status: 500,
          }
        );
      }
    } else {
      const resData = await response.json();
      console.log("got response ", resData);
      return resData;
    }
  } catch (error) {
    // Handle errors, including 403 Forbidden
    console.error("Error during settings fetch:", error);
    const status = error.status;
    console.error("status = ", status);
    return error;
  }
}
