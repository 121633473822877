import AlertDialog from "../alertDialog/AlertDialog";
import styles from "./PersonDetailsDialog.module.scss";
import CircularImage from "../CircularImage/CircularImage";
import userPlaceholderImageUrl from "../../../assets/userPlaceholder.png";
import educationOptionsJson from "../../../jsonConstants/educationTypes.json";
import gotraTypeJson from "../../../jsonConstants/gotraTypes.json";
import relationTypeJson from "../../../jsonConstants/relationTypes.json";
import yearlyIncomeOptionsJson from "../../../jsonConstants/yearlyIncome.json";
import maritalStatusOptionsJson from "../../../jsonConstants/maritalStatusTypes.json";
import weightRangeJson from "../../../jsonConstants/weightRange.json";
import heightFeetJson from "../../../jsonConstants/heightFeet.json";
import heightInchJson from "../../../jsonConstants/heightInch.json";
import statesAndCities from "../../../jsonConstants/statesAndCities.json";
import { textFor } from "../../../util/languages";
import { getKundliDoshas } from "../../memberDetails/fieldValueUtils";
import { LANGUAGE_KEY } from "../../../Constants";
import { useState } from "react";
import { getGotraName } from "../../../util/viewUtils";

const { educationOptions } = educationOptionsJson;
const { gotraTypeOptions } = gotraTypeJson;
const { relationTypeOptions } = relationTypeJson;
const { yearlyIncomeOptions } = yearlyIncomeOptionsJson;
const { maritalStatusOptions } = maritalStatusOptionsJson;
const { weightRangeOptions } = weightRangeJson;
const { heightFeetOptions } = heightFeetJson;
const { heightInchOptions } = heightInchJson;
function PersonDetailsDialog({
  onClose,
  personDetails,
  getViewForTopRightArea,
}) {
  const activeLanguage = localStorage.getItem(LANGUAGE_KEY) || "ENGLISH";
  const { states } = statesAndCities;
  //todo, understand below, why these 5 variables are working, whereas using state
  //it shows  "current component can't be updated when updating oher component"
  //so looks like, since the view of dialog will be in AlertDialog component, so it won't
  //ok to keep state variables here.
  let firstLine = "",
    secondLine = "",
    thirdLine = "",
    fourthLine = "",
    fifthLine = "";
  const [cities, setCities] = useState([]);

  function swichOnSeparatorIndex(separatorLineIndex) {
    switch (separatorLineIndex) {
      case 0:
        firstLine = "1";
        break;
      case 1:
        secondLine = "1";
        break;
      case 2:
        thirdLine = "1";
        break;
      case 3:
        fourthLine = "1";
        break;
      case 4:
        fifthLine = "1";
        break;
    }
  }
  function showFieldIfAvailable(name, field, separatorLineIndex) {
    if (field !== "" && field !== null && field !== undefined) {
      //parse YES/NO dropdown field
      if (field === true) {
        //for field value = true for yes no dropdown flags
        field = "YES";
      } else if (field === false) {
        field = "NO";
      }
      swichOnSeparatorIndex(separatorLineIndex);
      return (
        <div>
          <b>{name}</b> {field}
        </div>
      );
    }
  }
  function getKundliDoshaStr() {
    let doshStr = "";
    const kundliDoshas = getKundliDoshas(personDetails);
    let i = 0;
    if (kundliDoshas.length > 0) {
      for (let dosh of kundliDoshas) {
        i++;
        doshStr += textFor(dosh);
        if (i < kundliDoshas.length) {
          doshStr += ", ";
        }
      }
    } else {
      doshStr = textFor("none");
    }
    return doshStr;
  }

  function getAge(birthYear) {
    if (birthYear) {
      const currentYear = new Date().getFullYear();
      return currentYear - birthYear;
    } else {
      return "";
    }
  }

  function getHeight(feet, inches) {
    let retVal = "";
    if (feet) {
      const matchedRow = heightFeetOptions.filter((h) => h.id === feet);
      retVal =
        (activeLanguage === "ENGLISH"
          ? matchedRow[0].name
          : matchedRow[0].hindiName) +
        " " +
        textFor("feet");
      if (inches !== null && inches !== "" && inches.includes("INCH")) {
        const matchedRow = heightInchOptions.filter((h) => h.id === inches);
        retVal +=
          ", " +
          (activeLanguage === "ENGLISH"
            ? matchedRow[0].name
            : matchedRow[0].hindiName) +
          " " +
          textFor("inches");
      }
    }
    return retVal;
  }
  function getWeight(weightStr) {
    let retVal = "";
    if (weightStr) {
      const matchedRow = weightRangeOptions.filter((w) => w.id === weightStr);
      retVal =
        activeLanguage === "ENGLISH"
          ? matchedRow[0].name
          : matchedRow[0].hindiName;
    }
    return retVal;
  }
  function getMaritalStatus(mStr) {
    let retVal = "";
    if (mStr) {
      const matchedRow = maritalStatusOptions.filter((m) => m.id === mStr);
      retVal =
        activeLanguage === "ENGLISH"
          ? matchedRow[0].name
          : matchedRow[0].hindiName;
    }

    return retVal;
  }
  function getYearlyIncome(yiStr) {
    let retVal = "";
    if (yiStr) {
      const matchedRow = yearlyIncomeOptions.filter((yi) => yi.id === yiStr);
      retVal =
        activeLanguage === "ENGLISH"
          ? matchedRow[0].name
          : matchedRow[0].hindiName;
    }

    return retVal;
  }
  function getEducation(eduStr) {
    let retVal = "";
    if (eduStr) {
      const matchedRow = educationOptions.filter((e) => e.id === eduStr);
      retVal =
        activeLanguage === "ENGLISH"
          ? matchedRow[0].name
          : matchedRow[0].hindiName;
    }
    return retVal;
  }

  function getRelationName(relationStr) {
    if (relationStr === "SELF") {
      return textFor("self");
    }
    let retVal = relationStr;
    if (relationStr) {
      const matchedRow = relationTypeOptions.filter(
        (g) => g.id === relationStr
      );
      if (matchedRow) {
        retVal =
          activeLanguage === "ENGLISH"
            ? matchedRow[0].name
            : matchedRow[0].hindiName;
      }
    }
    return retVal;
  }

  function getStateName(stateStr) {
    let retVal = "stateStr";
    if (stateStr) {
      const matchedRow = states.filter((g) => g.id === stateStr);
      if (matchedRow.length > 0) {
        setCities(matchedRow[0].cities);
        retVal =
          activeLanguage === "ENGLISH"
            ? matchedRow[0].name
            : matchedRow[0].hindiName;
      }
    }
    return retVal;
  }

  function getCityName(cityStr) {
    let retVal = cityStr;
    if (cityStr) {
      const matchedRow = cities.filter((g) => g.id === cityStr);
      if (matchedRow.length > 0) {
        //below we are not use += so we should be fine
        retVal =
          activeLanguage === "ENGLISH"
            ? matchedRow[0].name
            : matchedRow[0].hindiName;
      }
    }
    return retVal;
  }

  console.log("got personDetails = ", personDetails);
  function getNameGenderAgeGotraMarital() {
    return (
      <>
        {showFieldIfAvailable(textFor("name") + ":", personDetails.fullName, 0)}
        {showFieldIfAvailable(
          textFor("connections") + ":",
          personDetails.friendCount,
          0
        )}
        {showFieldIfAvailable(
          textFor("gender") + ":",
          textFor(personDetails.genderType),
          0
        )}
        {showFieldIfAvailable(
          textFor("age") + ":",
          getAge(personDetails.birthYear),
          0
        )}
        {showFieldIfAvailable(
          textFor("gotra") + ":",
          getGotraName(personDetails.gotraType),
          0
        )}
        {showFieldIfAvailable(
          textFor("maritalStatus") + ":",
          getMaritalStatus(personDetails.maritalStatusType),
          0
        )}
      </>
    );
  }
  function getEduDetailsMarkup() {
    return (
      <>
        {showFieldIfAvailable(
          textFor("education") + ":",
          getEducation(personDetails.educationLevelType),
          1
        )}
        {showFieldIfAvailable(
          textFor("details") + ":",
          personDetails.educationDetails,
          1
        )}
      </>
    );
  }
  function getPersonDetailsView() {
    return (
      <div className={styles.detailsWrapper}>
        <div className={styles.topPanelWithImage}>
          <CircularImage
            profileImageUrl={
              personDetails.profileImageUrl || userPlaceholderImageUrl
            }
          />
          {getViewForTopRightArea()}
        </div>
        {getNameGenderAgeGotraMarital()}
        {firstLine && <hr />}
        {/*--------------------------------------------------------*/}
        {getEduDetailsMarkup()}
        {secondLine && <hr />}
        {/*--------------------------------------------------------*/}
        {showFieldIfAvailable(
          textFor("yearlyIncome") + ":",
          getYearlyIncome(personDetails.yearlyIncome),
          2
        )}
        {showFieldIfAvailable(
          textFor("source") + ":",
          personDetails.incomeSourceDetails,
          2
        )}
        {thirdLine && <hr />}
        {/*--------------------------------------------------------*/}
        {showFieldIfAvailable(
          textFor("speciallyAbled") + ":",
          personDetails.isSpeciallyAbled === true
            ? textFor("YES")
            : textFor("NO"),
          3
        )}
        {showFieldIfAvailable(
          textFor("specialAbilityDetails") + ":",
          personDetails.specialAbilityDetails,
          3
        )}
        {showFieldIfAvailable(
          textFor("height") + ":",
          getHeight(
            personDetails.heightFeetType,
            personDetails.heightInchesType,
            3
          )
        )}
        {showFieldIfAvailable(
          textFor("weight") + ":",
          getWeight(personDetails.weightRangeType),
          3
        )}
        {showFieldIfAvailable(
          textFor("pureVeg") + ":",
          personDetails.isPureVegetarian === true
            ? textFor("YES")
            : textFor("NO"),
          3
        )}
        {showFieldIfAvailable(
          textFor("kundliDosha") + ":",
          getKundliDoshaStr(),
          3
        )}
        {showFieldIfAvailable(
          textFor("otherDetails") + ":",
          personDetails.otherDetails,
          3
        )}
        {fourthLine && <hr />}
        {/*--------------------------------------------------------*/}
        {showFieldIfAvailable(
          textFor("relation") + ":",
          getRelationName(personDetails.familyRelationType),
          4
        )}
        {showFieldIfAvailable(
          textFor("guardian") + ":",
          personDetails.guardianName,
          4
        )}
        {showFieldIfAvailable(
          textFor("state") + ":",
          getStateName(personDetails.addressStateName),
          4
        )}
        {showFieldIfAvailable(
          textFor("city") + ":",
          getCityName(personDetails.addressCityName),
          4
        )}
        {showFieldIfAvailable(
          textFor("address") + ":",
          personDetails.detailedAddress,
          4
        )}
        {fifthLine && <hr />}
        {/*--------------------------------------------------------*/}
      </div>
    );
  }
  return (
    <AlertDialog
      onOkClick={onClose}
      onBackdropClicked={onClose}
      title={textFor("personDetails:")}
      getContent={getPersonDetailsView}
    />
  );
}
export default PersonDetailsDialog;
