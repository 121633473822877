import React from "react";
import styles from "./Dropdown.module.scss";
import { textFor } from "../../../util/languages";
import { LANGUAGE_KEY } from "../../../Constants";

const Dropdown = ({
  id = "dummy",
  name,
  label,
  value,
  onChange,
  options,
  disabled,
  message,
  multiple = false,
}) => {
  const activeLanguage = localStorage.getItem(LANGUAGE_KEY) || "ENGLISH";
  return (
    <label className={styles.label}>
      {label}
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={styles.inputField}
        multiple={multiple}
      >
        <option key="nothing" value="" disabled>
          {message || `Select ${label}`}
        </option>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {activeLanguage.toUpperCase() === "ENGLISH"
              ? option.name
              : option.hindiName}
          </option>
        ))}
      </select>
    </label>
  );
};

export default Dropdown;
