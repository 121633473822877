import { useEffect, useRef, useState } from "react";
import commonStyles from "../../commonStyles.module.scss";
import LoginCredentials from "./loginCredentials/LoginCredentials";
import AddressFieldsWrapper from "./addressDetails/AddressFieldsWrapper";
import MemberDetails from "../memberDetails/MemberDetails";
import { useNavigate } from "react-router-dom";
import WalletDetails from "../walletDetails/WalletDetails";
import { scrollTabToMakeFullyVisible } from "../../util/viewUtils";
import { doNavigate } from "../../util/auth";
import { ACTIVE_TAB_IN_PROFILE, LAST_PAGE } from "../../Constants";
import { textFor } from "../../util/languages";
function getActiveTab() {
  const activeTab = localStorage.getItem("activeTabInProfile");
  if (!activeTab) {
    return tabIds.loginDetails;
  }
  return activeTab;
}
const tabIds = {
  loginDetails: "LOGIN_DETAILS",
  address: "ADDRESS",
  personalDetails: "PERSONAL_DETAILS",
  wallet: "WALLET",
};
function ProfileDetails(props) {
  console.log("in ProfileDetails props is ", props);
  const navigate = useNavigate();
  const tabLinkRef = useRef(null);
  localStorage.setItem(LAST_PAGE, "profile");
  useEffect(() => {
    if ("status" in props.registrationDetails) {
      if (props.registrationDetails.status !== 200) {
        console.log(
          "Logging out because in ProfileDetails, status is ",
          props.registrationDetails.status
        );
        doNavigate(navigate, "/logout");
      } else {
        console.log("In ProfileDetails, status is not 200 , props = ", props);
      }
    }
  }, [props, navigate]);

  useEffect(() => {
    const activeTabName = getActiveTab();
    setActiveTab(activeTabName);
    if (activeTabName === tabIds.wallet) {
      tabLinkRef.current.scrollLeft = tabLinkRef.current.scrollWidth;
    } else if (activeTabName === tabIds.loginDetails) {
      tabLinkRef.current.scrollLeft = 0;
    }
  }, []);

  const [activeTab, setActiveTab] = useState(null);

  const tabClicked = (event) => {
    /* Since we have div and <i> as children in button, in the event.target it sometimes give <i> or <div> element
    when we closely click on them, but it should give the wrapper button id, so we have to use closest() method
    to find the closest button component from the clicked item.
     */
    scrollTabToMakeFullyVisible(event);
    const clickedTabId = event.target.closest("button").id;
    // console.log("tab clicked: ", clickedTabId);
    setActiveTab(clickedTabId);
    localStorage.setItem(ACTIVE_TAB_IN_PROFILE, clickedTabId);
  };
  function getWalletDetailsView() {
    return (
      <div
        className={commonStyles.tabContent}
        style={{
          display: activeTab === tabIds.wallet ? "block" : "none",
        }}
      >
        {activeTab === tabIds.wallet ? (
          <WalletDetails registrationDetails={props.registrationDetails} />
        ) : (
          ""
        )}
      </div>
    );
  }
  function getPersonalDetailsView() {
    return (
      <div
        className={commonStyles.tabContent}
        style={{
          display: activeTab === tabIds.personalDetails ? "block" : "none",
        }}
      >
        {activeTab === tabIds.personalDetails ? (
          <MemberDetails registrationDetails={props.registrationDetails} />
        ) : (
          ""
        )}
      </div>
    );
  }
  function getAddressView() {
    return (
      <div
        className={commonStyles.tabContent}
        style={{ display: activeTab === tabIds.address ? "block" : "none" }}
      >
        {activeTab === tabIds.address ? (
          <AddressFieldsWrapper
            registrationDetails={props.registrationDetails}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
  function getLoginDetailsView() {
    return (
      <div
        className={commonStyles.tabContent}
        style={{
          display: activeTab === tabIds.loginDetails ? "block" : "none",
        }}
      >
        {activeTab === tabIds.loginDetails ? (
          <LoginCredentials
            registrationDetails={{
              ...props.registrationDetails,
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
  function getTopTabButtonsRow() {
    return (
      <div ref={tabLinkRef} className={commonStyles.tab}>
        <button
          id={tabIds.loginDetails}
          className={`${commonStyles.tabLinks} ${
            activeTab === tabIds.loginDetails && commonStyles.activeTab
          }`}
          onClick={tabClicked}
        >
          <i className="fas fa-key"></i>
          <div className={commonStyles.tabTitle}>{textFor("loginDetails")}</div>
        </button>
        <button
          id={tabIds.address}
          className={`${commonStyles.tabLinks} ${
            activeTab === tabIds.address && commonStyles.activeTab
          }`}
          onClick={tabClicked}
        >
          <i className="fa-solid fa-location-dot"></i>
          <div className={commonStyles.tabTitle}>{textFor("address")}</div>
        </button>
        <button
          id={tabIds.personalDetails}
          className={`${commonStyles.tabLinks} ${
            activeTab === tabIds.personalDetails && commonStyles.activeTab
          }`}
          onClick={tabClicked}
        >
          <i className="fa-regular fa-address-card"></i>
          <div className={commonStyles.tabTitle}>
            {textFor("personalDetails")}
          </div>
        </button>
        <button
          id={tabIds.wallet}
          className={`${commonStyles.tabLinks} ${
            activeTab === tabIds.wallet && commonStyles.activeTab
          }`}
          onClick={tabClicked}
        >
          <i className="fa-regular fa-credit-card"></i>
          <div className={commonStyles.tabTitle}>
            {textFor("walletDetails")}
          </div>
        </button>
      </div>
    );
  }
  return (
    <>
      {getTopTabButtonsRow()}
      {getLoginDetailsView()}
      {getAddressView()}
      {getPersonalDetailsView()}
      {getWalletDetailsView()}
    </>
  );
}

export default ProfileDetails;
//todo in profile detils for person details tab, pressing clear will show relation type
//dropdown, for self details that should be hidden

//todo rename city with latest names
/*
Gurgaon → Gurugram (Hindi: गुरुग्राम) renamed in 2016
Allahabad → Prayagraj (Hindi: प्रयागराज), renamed in 2018
New Raipur → Atal Nagar (Hindi: अटल नगर), renamed in 2018
Hoshangabad → Narmadapuram (Hindi: नर्मदापुरम), renamed in 2021
Aurangabad → Chhatrapati Sambhajinagar (Marathi: छत्रपती संभाजीनगर), renamed in 2023
Osmanabad → Dharashiv (Marathi: धाराशिव), renamed in 2023
Ahmednagar → Ahilyanagar, renamed in 2023 */

//todo correct spelling of Gayriya to Gaariya
