import ReactDOM from "react-dom";
import styles from "../../pages/searchGroom/SearchFilters.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import { useEffect, useRef, useState } from "react";
import { BackDrop } from "../UI/ErrorModal";
import { LANGUAGE_KEY } from "../../Constants";
function MultiColMultiChoiceView({
  title,
  options,
  onClose,
  selectedItems,
  onChange,
}) {
  //todo why we have this component, when we already have MultiColumnMultiSelectView ??
  //is it that that component is not returning the expeccted value ??
  //because that is designed to use setFilterValue callback and I didn't want it to change
  const parentRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  let initiallySelectedItems = new Set();
  const [itemStatus, setItemStatus] = useState(initiallySelectedItems);
  useEffect(() => {
    selectedItems.forEach((element) => {
      toggleItemStatus(element);
    });
  }, []);
  const activeLanguage = localStorage.getItem(LANGUAGE_KEY) || "ENGLISH";
  function toggleItemStatus(value) {
    setScrollPosition(parentRef.current.scrollTop);
    setItemStatus((prevState) => {
      if (prevState.has(value)) {
        prevState.delete(value);
      } else {
        prevState.add(value);
      }
      return new Set(prevState);
    });
  }
  function closeDialog() {
    onChange([...itemStatus]);
    onClose();
  }
  function getMulticolumChoicesSelectionView() {
    return (
      <>
        {ReactDOM.createPortal(
          <BackDrop onConfirm={(e) => {}} />,
          document.getElementById("backdrop-root")
        )}
        {ReactDOM.createPortal(
          <MultiColumnCheckboxes />,
          document.getElementById("overlay-root")
        )}
      </>
    );
  }
  const MultiColumnCheckboxes = () => {
    return (
      <div className={styles.multicolumnCheckboxesWrapper}>
        <div className={styles.buttonPanel}>{title}</div>
        <div ref={parentRef} className={styles.matrixGrid}>
          {options.map((g) => (
            <div
              onClick={(e) => {
                e.stopPropagation();
                toggleItemStatus(g.id);
              }}
              key={g.id}
              className={styles.matrixGridItem}
            >
              {itemStatus.has(g.id) ? (
                <i
                  style={{ fontSize: "22px" }}
                  className="fa-regular fa-circle-check fa-1x"
                ></i>
              ) : (
                <i
                  style={{ fontSize: "20px" }}
                  className="fa-regular fa-circle fa-1x"
                ></i>
              )}
              <div>{activeLanguage === "ENGLISH" ? g.name : g.hindiName}</div>
              {/* <div className={styles.optionTextWrapper}>
                <div className={styles.optionText}>{g.name}</div>
              </div> */}
            </div>
          ))}
        </div>
        <div className={styles.buttonPanel}>
          <button
            style={{ padding: "5px 25px", margin: "2px 5px" }}
            className={commonStyles.orangeButton}
            onClick={closeDialog}
            type="button"
          >
            OK
          </button>
        </div>
      </div>
    );
  };
  return <>{getMulticolumChoicesSelectionView()}</>;
}

export default MultiColMultiChoiceView;
