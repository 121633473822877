import commonStyles from "../commonStyles.module.scss";
import { textFor } from "../util/languages";
function PrivacyPolicy() {
  return (
    <div
      className={commonStyles.scrollableContainer}
      style={{ height: "75vh" }}
    >
      <div>
        <h5
          className={commonStyles.centeredMessage}
          style={{ textDecoration: "underline" }}
        >
          {textFor("privacyPolicy")}
          <br /> {textFor("privacyPolicyUpdatedOn")}
        </h5>
        <ol>
          <li className={commonStyles.note1} key="1">
            {textFor("introduction")}
            <ul>
              <li
                key="1.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("privacyPolicyDescription")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="2">
            {textFor("infoWeCollect")}
            <ul>
              <li
                key="2.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("detailsWeCollectDuringRegistration")}
              </li>
              <li
                key="2.2"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("youMayChooseToProvideExtraInfo")}
              </li>
              <li
                key="2.3"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weCollectInfoAboutConnection")}
              </li>
              <li
                key="2.4"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weCollectPaymentRelatedInfo")}
              </li>
              <li
                key="2.5"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weCollectCustomerSupportInquiryDetails")}
              </li>
              <li
                key="2.6"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weCollectLogsEtc")}
              </li>
              <li
                key="2.7"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weUseBrowserLocalStorage")}
              </li>
            </ul>
          </li>
          <br />
          <li key="3" className={commonStyles.note1}>
            {textFor("howWeUseYourInfo")}
            <ul>
              <li
                key="3.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("toOperateSiteCreateUserAcct")}
              </li>
              <li
                key="3.2"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("toFindPeopleBasedOnSearchFilters")}
              </li>
              <li
                key="3.3"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("toSendYouImportantNotices")}
              </li>
              <li
                key="3.4"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("toAnalyzeUsagePatterns")}
              </li>
              <li
                key="3.5"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("toDetectAndPreventFraud")}
              </li>
              <li
                key="3.6"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("toComplyWithLaws")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="4">
            {textFor("howWeShareYourInfo")}
            <ul>
              <li
                key="4.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("yourProfileIsSharedWithOthers")}
              </li>
              <li
                key="4.2"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weMayShareYourInfoWith3rdParty")}
              </li>
              <li
                key="4.3"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weMayShareYourInfoWithLegalAuthorities")}
              </li>
              <li
                key="4.4"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("duringMergerYourInfoMayBeShared")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="5">
            {textFor("yourInformationOptions")}
            <ul>
              <li
                key="5.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("youCanUpdateYourProfile")}
              </li>
              <li
                key="5.2"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("youCanChangeLanguage")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="6">
            {textFor("infoSecurity")}
            <ul>
              <li
                key="6.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weTakeReasonableMeasures")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="7">
            {textFor("usageByUnderaged")}
            <ul>
              <li
                key="7.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("siteIsNotIntendedForChildren")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="8">
            {textFor("changesToPrivacyPolicy")}
            <ul>
              <li
                key="8.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weMayUpdatePrivacyPolicy")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="9">
            {textFor("dataRetention")}
            <ul>
              <li
                key="9.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("tillYourAccountIsActive")}
              </li>
              <li
                key="9.2"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("weStoreBackups")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="10">
            {textFor("yourConsent")}
            <ul>
              <li
                key="10.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("dontUseIfDontAgree")}
              </li>
            </ul>
          </li>
          <br />
          <li className={commonStyles.note1} key="11">
            {textFor("termsAndConditions")}
            <ul>
              <li
                key="11.1"
                className={`${commonStyles.bullet} ${commonStyles.note2}`}
              >
                {textFor("thereIsSeparateTermsDoc")}
              </li>
            </ul>
          </li>
        </ol>
        <hr />
      </div>
    </div>
  );
}

export default PrivacyPolicy;
