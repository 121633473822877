import Dropdown from "../../components/UI/dropdown/Dropdown";
import { useEffect, useState } from "react";
import commonStyles from "../../commonStyles.module.scss";
import { doNavigate } from "../../util/auth";
import {
  getAgeFilterView,
  getKundliDoshaFilterView,
  getEducationFilterView,
  getHeightFilterView,
  getWeightFilterView,
  getGotraFilterView,
  getMaritalStatusFilterView,
  getSpeciallyAbledFilterView,
  getPureVegFilterView,
  getYearlyIncomeFilterView,
  getDistanceFilterView,
} from "./SearchFilters";
import AlertDialog from "../../components/UI/alertDialog/AlertDialog";
import { useLocation, useNavigate } from "react-router-dom";
import {
  OPTIONAL_FILTERS,
  SEARCH_GROOM_CRITERIA,
  DISTANCE_RANGE,
  MIN_EDU,
  AGE,
  GOTRA,
  HEIGHT,
  WEIGHT,
  MARITAL_STATUS,
  KUNDLI_DOSHA,
  PURE_VEG,
  SPECIAL_ABILITY,
  MINIMUM_YEARLY_INCOME,
  MIN_AGE,
  MAX_AGE,
  MIN_WEIGHT,
  MAX_WEIGHT,
  FROM_HEIGHT_FEET,
  FROM_HEIGHT_INCHES,
  TO_HEIGHT_FEET,
  TO_HEIGHT_INCHES,
  LOW_BALANCE_ALERT_SHOWN,
} from "../../Constants";
import {
  checkIfAFilterEmptyAndSaveFiltersToLocalStorage,
  resultsAvailableInCache,
} from "../../util/filterUtil";
import { textFor } from "../../util/languages";
import { saveActiveRoute } from "../../util/viewUtils";

function usePropsToGetStates(props) {
  const initialFilterData = {};
  initialFilterData[OPTIONAL_FILTERS] = "";
  initialFilterData[SEARCH_GROOM_CRITERIA] = "";
  initialFilterData[DISTANCE_RANGE] = "";
  initialFilterData[MIN_EDU] = "";
  initialFilterData[AGE] = "";
  initialFilterData[GOTRA] = "";
  initialFilterData[HEIGHT] = "";
  initialFilterData[WEIGHT] = "";
  initialFilterData[MARITAL_STATUS] = "";
  initialFilterData[KUNDLI_DOSHA] = "";
  initialFilterData[PURE_VEG] = "";
  initialFilterData[SPECIAL_ABILITY] = "";
  initialFilterData[MINIMUM_YEARLY_INCOME] = "";
  initialFilterData[MIN_AGE] = "";
  initialFilterData[MAX_AGE] = "";
  initialFilterData[MIN_WEIGHT] = "";
  initialFilterData[MAX_WEIGHT] = "";
  initialFilterData[FROM_HEIGHT_FEET] = "";
  initialFilterData[FROM_HEIGHT_INCHES] = "";
  initialFilterData[TO_HEIGHT_FEET] = "";
  initialFilterData[TO_HEIGHT_INCHES] = "";

  const [formData, setFormData] = useState(initialFilterData);
  function setFilterValue(filterName, value) {
    console.log("filterName  = ", filterName);
    setFormData((prevState) => ({ ...prevState, [filterName]: value }));
  }
  return { formData, setFilterValue };
}
function SearchGroomCriteria(props) {
  const activeRoute = useLocation();
  // dispatch(clearSearchedGroomResults());
  const navigate = useNavigate();
  const { formData, setFilterValue } = usePropsToGetStates(props);
  const [valueForAddFilterDropdown, setValueForAddFilterDropdown] =
    useState("");
  const [alertDialogFields, setAlertDialogFields] = useState({
    shouldBeVisible: false,
    title: "",
    message: "",
  });
  const [showChargeConfirmDialog, setShowChargeConfirmDialog] = useState("");
  const [chargeConfirmed, setChargeConfirmed] = useState("");
  const [optionalFilters, setOptionalFilters] = useState([]);
  const initialSortedFilterTypes = [
    {
      id: DISTANCE_RANGE,
      name: textFor("distanceRange"),
      hindiName: textFor("distanceRange"),
    },
    {
      id: MARITAL_STATUS,
      name: textFor("maritalStatus"),
      hindiName: textFor("maritalStatus"),
    },
    {
      id: MIN_EDU,
      name: textFor("minEducation"),
      hindiName: textFor("minEducation"),
    },
    {
      id: AGE,
      name: textFor("age"),
      hindiName: textFor("age"),
    },
    {
      id: MINIMUM_YEARLY_INCOME,
      name: textFor("minYearlyIncome"),
      hindiName: textFor("minYearlyIncome"),
    },
    {
      id: GOTRA,
      name: textFor("gotra"),
      hindiName: textFor("gotra"),
    },
    {
      id: HEIGHT,
      name: textFor("height"),
      hindiName: textFor("height"),
    },
    {
      id: WEIGHT,
      name: textFor("weight"),
      hindiName: textFor("weight"),
    },
    {
      id: SPECIAL_ABILITY,
      name: textFor("specialAbility"),
      hindiName: textFor("specialAbility"),
    },
    {
      id: KUNDLI_DOSHA,
      name: textFor("kundliDosha"),
      hindiName: textFor("kundliDosha"),
    },
    {
      id: PURE_VEG,
      name: textFor("pureVeg"),
      hindiName: textFor("pureVeg"),
    },
  ];
  const [availableFilterTyepes, setAvailableFilterTypes] = useState(
    initialSortedFilterTypes
  );
  localStorage.removeItem(LOW_BALANCE_ALERT_SHOWN);
  useEffect(() => {
    saveActiveRoute(activeRoute);
    if (chargeConfirmed) {
      doNavigate(navigate, "/searchedGrooms");
    }
  }, [chargeConfirmed]);
  useEffect(() => {
    const savedOptionalFilters = localStorage.getItem(OPTIONAL_FILTERS);
    if (savedOptionalFilters !== null) {
      const filters = JSON.parse(savedOptionalFilters);
      setOptionalFilters((prevState) => filters);
      const remainingFilterTypes = availableFilterTyepes.filter(
        (item) => !filters.includes(item.id)
      );
      setAvailableFilterTypes([...remainingFilterTypes]);
      const filterValues = JSON.parse(
        localStorage.getItem(SEARCH_GROOM_CRITERIA)
      );
      Object.keys(filterValues).map((k) => {
        setFilterValue(k, filterValues[k]);
        return k;
      });

      //// localStorage.removeItem(OPTIONAL_FILTERS);
      //// localStorage.removeItem(SEARCH_GROOM_CRITERIA);
    }
  }, []);
  useEffect(() => {
    console.log("optionalFilters = ", optionalFilters);
    localStorage.setItem(OPTIONAL_FILTERS, JSON.stringify(optionalFilters));
  }, [optionalFilters]);
  useEffect(() => {
    localStorage.setItem(SEARCH_GROOM_CRITERIA, JSON.stringify(formData));
  }, [formData]);
  function isNoFilterChosen() {
    return optionalFilters.length < 1;
  }
  function showAlertThatNoFilterIsChosen() {
    setAlertDialogFields((prevState) => {
      return {
        shouldBeVisible: true,
        title: textFor("noFilterSelected"),
        message: textFor("pleaseAddAtLeastOneFilter"),
      };
    });
  }
  function showAlertThatMinWeightShouldBeLess() {
    setAlertDialogFields((prevState) => {
      return {
        shouldBeVisible: true,
        title: "Invalid weight!",
        message: "Min weight must be less than max weight.",
      };
    });
  }
  function showAlertThatNoWeightIsChosen() {
    setAlertDialogFields((prevState) => {
      return {
        shouldBeVisible: true,
        title: "Please choose weight!",
        message: "Please choose Min or Max weight.",
      };
    });
  }
  function alertDisplayedForWeight() {
    //for now we are not showing any min<max validation
    //because in the filterUtils we use correction of filter e.g. correctMinMaxAge()

    // if (optionalFilters.includes(WEIGHT)) {
    //   console.log("formData in weightCheck = ", formData);
    //   if (formData.minWeight === "" && formData.maxWeight === "") {
    //     showAlertThatNoWeightIsChosen();
    //     return true;
    //   } else if (formData.minWeight !== "" && formData.maxWeight !== "") {
    //     if (formData.minWeight > formData.maxWeight) {
    //       showAlertThatMinWeightShouldBeLess();
    //       return true;
    //     }
    //   }
    // }
    return false;
  }
  function noAlertShownDuringValidtion() {
    //return true if all validations are ok
    //else show alert and return false
    if (alertDisplayedForWeight()) {
      return false;
    }
    return true;
  }
  function onSearchClick(e) {
    //todo: here see, we are doing so many checks and operations
    //so we shoud show loader and then do all these in the click handler.
    console.log("optionalFilters = ", optionalFilters);
    console.log("formData = ", formData);
    if (isNoFilterChosen()) {
      showAlertThatNoFilterIsChosen();
    } else if (noAlertShownDuringValidtion()) {
      let incompleteFilterMsg = { value: "" };
      //this will also fill in incompleteFilterMsg
      if (
        checkIfAFilterEmptyAndSaveFiltersToLocalStorage(
          optionalFilters,
          formData,
          SEARCH_GROOM_CRITERIA,
          OPTIONAL_FILTERS,
          incompleteFilterMsg
        )
      ) {
        showDialogToSelectValuesFromAllFilters(incompleteFilterMsg.value);
      } else {
        //don't show charge if cache is there
        if (resultsAvailableInCache()) {
          setChargeConfirmed("1");
        } else {
          setShowChargeConfirmDialog("1");
        }
      }
    }
  }
  function showDialogToSelectValuesFromAllFilters(mesg) {
    setAlertDialogFields((prevState) => {
      return {
        shouldBeVisible: true,
        title: textFor("emptyFilters"),
        message: mesg,
      };
    });
  }

  function removeFieldFromCriteria(idStr) {
    console.log("removing field: ", idStr);
    setOptionalFilters((prevState) => {
      const updatedOptionalFilters = prevState.filter((item) => item !== idStr);
      return [...updatedOptionalFilters];
    });
    //put this id to remove in Min Edu idArray
    let idArray = [idStr];

    //append other filts Ids into that idArray
    availableFilterTyepes.map((f) => {
      idArray.push(f.id);
      return f;
    });
    //now idArray has all the ids of filters to show
    //just show them in sorted order, so create a
    //sortedFilters array having the fields in sorted order
    let sortedFilters = [];
    initialSortedFilterTypes.map((fObj) => {
      if (idArray.includes(fObj.id)) {
        sortedFilters.push(fObj);
      }
      return fObj;
    });
    setAvailableFilterTypes([...sortedFilters]);
  }
  function getDistanceRangeFilter() {
    return getDistanceFilterView(
      textFor("distanceRange"),
      (e) => removeFieldFromCriteria(DISTANCE_RANGE),
      formData,
      setFilterValue
    );
  }
  function getMinimumEducationFilter() {
    return getEducationFilterView(
      textFor("minEducation"),
      (e) => removeFieldFromCriteria(MIN_EDU),
      formData,
      setFilterValue
    );
  }
  function getAgeFilter() {
    return getAgeFilterView(
      textFor("age"),
      (e) => removeFieldFromCriteria(AGE),
      formData,
      setFilterValue
    );
  }
  function getHeightFilter() {
    return getHeightFilterView(
      textFor("height"),
      (e) => removeFieldFromCriteria(HEIGHT),
      formData,
      setFilterValue
    );
  }
  function getWeightFilter() {
    return getWeightFilterView(
      textFor("weight"),
      (e) => removeFieldFromCriteria(WEIGHT),
      formData,
      setFilterValue
    );
  }
  function getGotraFilter() {
    return getGotraFilterView(
      textFor("gotra"),
      (e) => removeFieldFromCriteria(GOTRA),
      formData,
      setFilterValue
    );
  }
  function getMaritalStatusFilter() {
    return getMaritalStatusFilterView(
      textFor("maritalStatus"),
      (e) => removeFieldFromCriteria(MARITAL_STATUS),
      formData,
      setFilterValue
    );
  }
  function getSpecialAbilityFilter() {
    return getSpeciallyAbledFilterView(
      textFor("specialAbility"),
      (e) => removeFieldFromCriteria(SPECIAL_ABILITY),
      formData,
      setFilterValue
    );
  }
  function getKundliDoshaFilter() {
    return getKundliDoshaFilterView(
      textFor("kundliDosha"),
      (e) => removeFieldFromCriteria(KUNDLI_DOSHA),
      formData,
      setFilterValue,
      KUNDLI_DOSHA
    );
  }
  function getPureVegFilter() {
    return getPureVegFilterView(
      textFor("pureVeg"),
      (e) => removeFieldFromCriteria(PURE_VEG),
      formData,
      setFilterValue
    );
  }
  function getYearlyIncomeFilter() {
    return getYearlyIncomeFilterView(
      textFor("minYearlyIncome"),
      (e) => removeFieldFromCriteria(MINIMUM_YEARLY_INCOME),
      formData,
      setFilterValue
    );
  }
  function getFilterOfType(type) {
    let filter = "";
    switch (type) {
      case DISTANCE_RANGE:
        filter = getDistanceRangeFilter();
        break;
      case MIN_EDU:
        filter = getMinimumEducationFilter();
        break;
      case AGE:
        filter = getAgeFilter();
        break;
      case HEIGHT:
        filter = getHeightFilter();
        break;
      case WEIGHT:
        filter = getWeightFilter();
        break;
      case GOTRA:
        filter = getGotraFilter();
        break;
      case MARITAL_STATUS:
        filter = getMaritalStatusFilter();
        break;
      case SPECIAL_ABILITY:
        filter = getSpecialAbilityFilter();
        break;
      case PURE_VEG:
        filter = getPureVegFilter();
        break;
      case MINIMUM_YEARLY_INCOME:
        filter = getYearlyIncomeFilter();
        break;
      case KUNDLI_DOSHA:
        filter = getKundliDoshaFilter();
        break;
      default:
        console.log("UNHANDLED TYPE: ", type);
        break;
    }
    return filter;
  }
  function getOptionalFilters() {
    return (
      <>
        {optionalFilters.map((f, index) => {
          // console.log("got value ", f);
          return (
            <div className={commonStyles.optionalFilterStyle} key={index}>
              {getFilterOfType(f)}
            </div>
          );
        })}
      </>
    );
  }
  function addSelectedFilter(e) {
    console.log("selectedFilter = ", e.target.value);
    //for small amount of time set it
    // NO NEED of this setValueForAddFilterDropdown(e.target.value);
    setOptionalFilters((prevFilters) => [...prevFilters, e.target.value]);
    const remainingFilterTypes = availableFilterTyepes.filter(
      (item) => item.id !== e.target.value
    );
    setAvailableFilterTypes([...remainingFilterTypes]);
    //The add Filter dropdown should always show blank
    setValueForAddFilterDropdown("");
  }
  function setAlertDialogVisibility(visibilityFlag) {
    setAlertDialogFields((prevState) => {
      const newState = { ...prevState, shouldBeVisible: visibilityFlag };
      return { ...newState };
    });
  }
  function closeAlert(e) {
    setAlertDialogVisibility(false);
  }
  function getSearchCriteriaView() {
    return (
      <>
        {alertDialogFields.shouldBeVisible ? (
          <AlertDialog
            onOkClick={closeAlert}
            onBackdropClicked={(e) => {}}
            title={alertDialogFields.title}
            getContent={() => (
              <p id="dialogMesg">{alertDialogFields.message}</p>
            )}
          />
        ) : (
          <>
            <div
              className={commonStyles.topPanel}
              style={{ color: "black", fontWeight: "bold" }}
            >
              {textFor("searchCriteria")}
            </div>
            <div className={commonStyles.scrollableContainer}>
              {getOptionalFilters()}
              {optionalFilters.length > 0 && (
                <div style={{ color: "black" }}>
                  <br /> <br />
                </div>
              )}
              {availableFilterTyepes.length && (
                <div style={{ margin: "15px 5px 0 5px" }}>
                  <Dropdown
                    name="addFilter"
                    message={textFor("addFilter")}
                    label=""
                    value={valueForAddFilterDropdown}
                    onChange={addSelectedFilter}
                    options={availableFilterTyepes}
                    disabled={false}
                  />
                </div>
              )}

              <button
                id="peopleSearchButton"
                className={commonStyles.blueButton}
                style={{ width: "7em", marginLeft: "5px" }}
                type="button"
                onClick={onSearchClick}
              >
                {textFor("search")}
              </button>
            </div>
          </>
        )}
        {showChargeConfirmDialog && (
          <AlertDialog
            onOkClick={(e) => {
              setChargeConfirmed("1");
              setShowChargeConfirmDialog("");
            }}
            cancelButtonText="No"
            onCancelClick={(e) => {
              setChargeConfirmed("");
              setShowChargeConfirmDialog("");
            }}
            onBackdropClicked={(e) => {}}
            title={textFor("pleaseConfirm")}
            getContent={() => <p>{textFor("perResultChargesMessage")}</p>}
          />
        )}
      </>
    );
  }

  return <>{getSearchCriteriaView()}</>;
}

export default SearchGroomCriteria;
