import React, { useState } from "react";
import { FaPencilAlt } from "react-icons/fa"; // Importing the pencil icon from react-icons library
// import { CiCoffeeCup } from "react-icons/ci";
import styles from "./EditableField.module.scss";
import ModalWithOtp from "../../../../UI/modal/ModalWithOtp";
import { textFor } from "../../../../../util/languages";

const EditableFieldWithOtp = ({
  idText,
  placeholder,
  heading,
  initialFieldValue,
  updateFieldValue,
  userInputTitle,
  userInputValidator,
  isEnabled = true,
  messageOnDisabled = textFor("notAllowed"),
  messageOnInvalidInput,
  maxLength = 100,
}) => {
  const [isEditing, setEditing] = useState(false);

  const handleEditClick = () => {
    if (isEnabled) {
      setEditing(true);
      console.log("edit clicked...");
    } else {
      alert(messageOnDisabled);
    }
  };

  const handleSaveClick = (newFeildValue) => {
    console.log("save clicked: newFeildValue=", newFeildValue);
    setEditing(false);
    updateFieldValue(newFeildValue);
  };

  const handleCancelClick = (event) => {
    console.log("cancel clicked ", event);
    setEditing(false);
  };

  return (
    <>
      <div className={styles.readOnlyFieldContainer}>
        <div id={idText + "ReadOnly"} className={styles.readOnlyField}>
          {initialFieldValue}
        </div>
        <div
          id={idText + "Pencil"}
          className={styles.editIcon}
          onClick={handleEditClick}
        >
          <FaPencilAlt />
        </div>
      </div>
      {isEditing ? (
        <ModalWithOtp
          onCancel={handleCancelClick}
          onOk={handleSaveClick}
          placeholder={placeholder}
          modalHeading={heading}
          userInputTitle={userInputTitle}
          userInputValidator={userInputValidator}
          maxLength={maxLength}
          messageOnInvalidInput={messageOnInvalidInput}
        ></ModalWithOtp>
      ) : (
        ""
      )}
    </>
  );
};

export default EditableFieldWithOtp;
