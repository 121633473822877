import AlertDialog from "../components/UI/alertDialog/AlertDialog";
import { textFor } from "../util/languages";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import commonStyles from "../commonStyles.module.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function HelpTermsAndPrivacyPolicyView({}) {
  const navigate = useNavigate();
  const [showTerms, setTermsVisibility] = useState("");
  const [showPrivacyPolicy, setPrivacyPolicyVisibility] = useState("");
  const getTermsAndConditions = () => {
    function hideTermsDialog() {
      setTermsVisibility((prevState) => {
        return prevState !== "" ? "" : "1";
      });
    }
    function getTermsDialogView() {
      return <TermsAndConditions />;
    }
    return (
      <AlertDialog
        onOkClick={hideTermsDialog}
        onBackdropClicked={hideTermsDialog}
        title={textFor("pleaseReadCarefully")}
        getContent={getTermsDialogView}
        okButtonText="OK"
      />
    );
  };
  const getPrivacyPolicy = () => {
    function hidePrivacyPolicyDialog() {
      setPrivacyPolicyVisibility((prevState) => {
        return prevState !== "" ? "" : "1";
      });
    }
    function getPrivacyPolicyDialogView() {
      return <PrivacyPolicy />;
    }
    return (
      <AlertDialog
        onOkClick={hidePrivacyPolicyDialog}
        onBackdropClicked={hidePrivacyPolicyDialog}
        title={textFor("pleaseReadCarefully")}
        getContent={getPrivacyPolicyDialogView}
        okButtonText="OK"
      />
    );
  };
  return (
    <>
      {showTerms === "1" ? getTermsAndConditions() : ""}
      {showPrivacyPolicy === "1" ? getPrivacyPolicy() : ""}
      <div
        className={commonStyles.bottomSection}
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          // border: "1px solid yellow",
        }}
      >
        <span
          id="termsLink"
          className={commonStyles.fieldLabel}
          style={{ textDecoration: "underline" }}
          onClick={() => setTermsVisibility("1")}
        >
          {textFor("termsAndConditions")}
        </span>
        <div style={{ margin: "0 10px" }}></div>
        <span
          id="privacyPolicyLink"
          className={commonStyles.fieldLabel}
          style={{ textDecoration: "underline" }}
          onClick={() => setPrivacyPolicyVisibility("1")}
        >
          {textFor("privacyPolicy")}
        </span>
        <div style={{ margin: "0 10px" }}></div>
        <span
          id="helpLink"
          className={commonStyles.fieldLabel}
          style={{
            backgroundColor: "darkGreen",
            padding: "0 15px",
            borderRadius: "5px",
          }}
          onClick={() => navigate("/help")}
        >
          {textFor("help")}
        </span>
      </div>
    </>
  );
}

export default HelpTermsAndPrivacyPolicyView;
