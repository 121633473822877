import { useEffect, useState } from "react";
import commonStyles from "../../commonStyles.module.scss";
import styles from "./WalletDetails.module.scss";
import AlertDialog from "../UI/alertDialog/AlertDialog";
import Dropdown from "../UI/dropdown/Dropdown";
import balancePlanJson from "../../jsonConstants/balancePlan.json";
import { BASE_URL, LAST_PAGE, LOW_BALANCE_ALERT_SHOWN } from "../../Constants";
import { doLogout, doNavigate, getAuthToken } from "../../util/auth";
import { useDispatch } from "react-redux";
import { loadRegistrationDetails } from "../../pages/ProfileLoader";
import { useSelector } from "react-redux";
import { Loader } from "../UI/ErrorModal";
import { updateBalance } from "../../store/reducers/userProfileSlice";
import { authActions } from "../../store/reducers/authSlice";
import { useNavigate } from "react-router-dom";
import { textFor } from "../../util/languages";
function WalletDetails({ registrationDetails }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (!auth.isAuthenticated) {
      doNavigate(navigate, "/welcome");
    }
  }, [auth.isAuthenticated, navigate]);

  const userProfile = useSelector((state) => state.userProfile);
  console.log("registrationDetails in wallet = ", registrationDetails);
  const [showPromotion, setShowPromotion] = useState("");
  const [showTransactions, setShowTransactions] = useState("");
  const [transactions, setTransactions] = useState("");
  const [isLoading, setIsLoading] = useState("");
  let isFetching = false;
  useEffect(() => {
    async function updateProfileDetails() {
      setIsLoading("1");
      await loadRegistrationDetails(dispatch, { id: "" }); //pass userProfile with empty id
      setIsLoading("");
    }
    updateProfileDetails();
  }, []);
  function onAddBalanceClicked(e) {
    setShowPromotion("1");
  }
  const [selectedBalancePlan, setSelectedBalancePlan] = useState("");
  const { balancePlans } = balancePlanJson;
  function getAddBalanceView() {
    return (
      <div className={styles.addBalanceSection}>
        <div style={{ flexGrow: "1", padding: "0", margin: "0" }}>
          <Dropdown
            name="addBalance"
            message={textFor("selectAmount")}
            value={selectedBalancePlan}
            onChange={(e) => setSelectedBalancePlan(e.target.value)}
            options={balancePlans}
            disabled={false} //disable if not editing
          />
        </div>
        <button
          type="button"
          className={commonStyles.greenButton}
          style={{ margin: "0 0 5px 0" }}
          onClick={onAddBalanceClicked}
          id="addBalanceButton"
        >
          {textFor("addBalance")}
        </button>
      </div>
    );
  }

  function getViewForTransactionHistoryButton() {
    return (
      <div className={styles.transactionHistorySection}>
        <button
          type="button"
          className={commonStyles.blueButton}
          onClick={getTransactions}
          id="viewTransactionsButton"
        >
          {textFor("viewTransactions")}
        </button>
      </div>
    );
  }

  function getCurrentBalanceView() {
    return (
      <div className={styles.balanceAndHistoryWrapper}>
        <div className={styles.balanceSection}>
          <div>
            <div>{textFor("currentBalance:")} </div>
            <div style={{ fontSize: "12px" }}>
              {textFor("balanceValidity")}{" "}
            </div>
          </div>
          <div className={styles.balanceDigits}>{userProfile.balance}</div>
        </div>
        {getViewForTransactionHistoryButton()}
      </div>
    );
  }

  function getPromotionalOfferView() {
    if (selectedBalancePlan) {
      localStorage.removeItem(LOW_BALANCE_ALERT_SHOWN);
      const message = textFor("pressOkToGetBalance") + selectedBalancePlan;
      return (
        <>
          <div id="dialogMesg1">{message}</div>
          <div id="dialogMesg2">
            {textFor("balanceIsValidTill")} <b>31st-May-2025.</b>
          </div>
        </>
      );
    } else {
      return <div>{textFor("pleaseSelectAmount")}</div>;
    }
  }
  function getTransactionHistoryDialogView() {
    if (transactions) {
      function getDateTime(timestampString) {
        // Create a Date object from the timestamp string
        const date = new Date(timestampString);

        // Extract components for day, month, year, hours, and minutes
        const day = String(date.getDate()).padStart(2, "0");
        const month = date.getMonth();

        // Get the first 3 letters of the month name
        const fullMonth = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ][month];

        const year = date.getFullYear() - 2000;
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");

        // Format the date string
        return `${day}-${fullMonth}-${year} ${hours}:${minutes}`;
      }
      return (
        <div className={styles.historyTableContainer}>
          <table className={styles.historyTable}>
            <thead>
              <tr>
                <th>{textFor("amount")}</th>
                <th>{textFor("details")}</th>
                <th style={{ width: "40px" }}>{textFor("total")}</th>
                <th>{textFor("timestamp")}</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((t, index) => {
                return (
                  <tr key={t.id}>
                    <td>{t.amount}</td>
                    <td>{t.description}</td>
                    <td>{t.totalBalance}</td>
                    <td style={{ fontSize: "10px" }}>
                      {getDateTime(t.createdOn)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else {
      return <div>No transaction found.</div>;
    }
  }
  async function getTransactions(e) {
    if (!isFetching) {
      isFetching = true; //to avoid multiple clicks
      setIsLoading("1");
      try {
        let url = BASE_URL + "/normalUser/walletTransactions";
        const headers = {
          Authorization: `Bearer ${getAuthToken()}`,
          "Content-Type": "application/json",
        };
        const response = await fetch(url, {
          headers,
        });

        console.log("response is = ", response);
        if (!response.ok) {
          if (response.status === 403) {
            doLogout(dispatch, authActions);
          } else {
            alert("Invalid response: " + response);
          }
        } else {
          const fetchedTransactions = await response.json();
          console.log("fetched transactions: ", fetchedTransactions);
          if (fetchedTransactions.length > 0) {
            const mostRecentTotalAmount = fetchedTransactions[0].totalBalance;
            if (userProfile.balance != mostRecentTotalAmount) {
              dispatch(updateBalance({ balance: mostRecentTotalAmount }));
            }
          }
          setTransactions(fetchedTransactions);
        }
      } catch (error) {
        console.error("Error during GET transaction request:", error);
        alert("Error: " + error);
      } finally {
        isFetching = false;
        setIsLoading("");
        setShowTransactions("1");
      }
    }
  }
  async function addBalanceForUser(e) {
    if (!isFetching && selectedBalancePlan) {
      isFetching = true;
      setIsLoading("1");
      try {
        let url = BASE_URL + "/normalUser/updateRegistrationDetails";
        const headers = {
          Authorization: `Bearer ${getAuthToken()}`,
          "Content-Type": "application/json",
        };
        const response = await fetch(url, {
          method: "PATCH",
          headers,
          body: JSON.stringify({
            amount: selectedBalancePlan,
            amountAction: "CREDIT",
            amountDescription: "ADD_BALANCE",
          }),
        });

        console.log("response is = ", response);
        if (!response.ok) {
          if (response.status === 403) {
            doLogout(dispatch, authActions);
          } else {
            alert("Invalid response: " + response);
          }
        } else {
          await loadRegistrationDetails(dispatch, { id: "" }); //pass userProfile with empty id
        }
      } catch (error) {
        console.error("Error during POST request:", error);
        alert("Error: " + error);
      } finally {
        isFetching = false;
        setIsLoading("");
      }
    } //if !isFetching
    hidePromotionMesg(e);
  }
  function hidePromotionMesg(e) {
    setShowPromotion("");
  }
  function hideTransactionDialog(e) {
    setShowTransactions("");
  }
  function getAlertViewForPromotion() {
    return (
      <AlertDialog
        onOkClick={addBalanceForUser}
        onBackdropClicked={hidePromotionMesg}
        title={textFor("promotionalOffer")}
        getContent={getPromotionalOfferView}
        okButtonText="OK"
      />
    );
  }
  function getAlertViewForTransactions() {
    return (
      <AlertDialog
        onOkClick={hideTransactionDialog}
        onBackdropClicked={(e) => {}}
        title={textFor("transactionHistory:")}
        getContent={getTransactionHistoryDialogView}
        okButtonText="Close"
      />
    );
  }

  return (
    <>
      {getCurrentBalanceView()}
      {getAddBalanceView()}
      {showPromotion && getAlertViewForPromotion()}
      {showTransactions && getAlertViewForTransactions()}
      {isLoading && <Loader />}
    </>
  );
}
export default WalletDetails;

//todo sometimes from desktop, we can choose the "select msg" from dropdown
//make sure it is not breaking the app or backend
