import styles from "../../pages/searchGroom/SearchedPeopleToMarryGridView.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import userPlaceholderImageUrl from "../../assets/userPlaceholder.png";
import { useEffect, useRef, useState } from "react";
import PersonDetailsDialog from "../UI/PersonDetailsDialog/PersonDetailsDialog";
import { useLocation } from "react-router-dom";
import { textFor } from "../../util/languages";
import { getFixedFullName, getGotraAndAge } from "../../util/viewUtils";
function FriendsInGridView({
  data,
  loadMoreClicked,
  getViewForTopRightArea,
  setMethodToHidePersonDetailDialog = null,
  getViewForPreviewTopRightIcon = null, //to show incoming or outgoing icon for rejected req
}) {
  // console.log("data: ", data);
  const viewRef = useRef(null);
  const [personToViewDetail, setPersonToViewDetail] = useState(null);
  const [shouldShowMemberDetailDialog, setDialogShow] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);
  const activeRoute = useLocation();
  if (setMethodToHidePersonDetailDialog) {
    setMethodToHidePersonDetailDialog(setDialogShow);
  }
  //------------------------------------------------------------------
  useEffect(() => {
    // Restore scroll position after the state update
    if (viewRef.current) {
      viewRef.current.scrollTop = scrollPosition;
      // console.log("setting scroll position to ", scrollPosition);
    }
  }, [shouldShowMemberDetailDialog]);
  //------------------------------------------------------------------
  function saveScrollPosition() {
    setScrollPosition(viewRef.current.scrollTop);
  }
  function viewPerson(member) {
    const m = { ...member };
    console.log("Viewing person = ", m);
    setPersonToViewDetail(m);
    saveScrollPosition();
    setDialogShow("1");
  }

  function getFullNameField(m) {
    return (
      <div className={commonStyles.fullNameWrapper}>
        <div className={commonStyles.fullName}>{getFixedFullName(m)}</div>
      </div>
    );
  }

  function getImageAndTextForMemberView(m) {
    let profileImageUrl = m.profileImageUrl;
    if (!profileImageUrl) {
      profileImageUrl = userPlaceholderImageUrl;
    }
    return (
      <>
        {getViewForPreviewTopRightIcon && getViewForPreviewTopRightIcon(m)}
        <div
          onClick={(e) => {
            viewPerson(m);
            //don't call a method on onClick, provide a function which will do the calling or just provide name of the function
          }}
          className={styles.imgDiv}
        >
          <img src={profileImageUrl} alt="profilePic" />
        </div>
        {getFullNameField(m)}
        {getGotraAndAge(m)}
      </>
    );
  }
  function getMemberView(m) {
    // console.log("key=", m.id);
    return (
      <div key={m.id} className={commonStyles.grid__item}>
        {getImageAndTextForMemberView(m)}
      </div>
    );
  }
  function getStylesForMemberGrid() {
    if (activeRoute.pathname.includes("friends")) {
      return `${commonStyles.grid} ${commonStyles.gridInsideTab}`;
    } else {
      return `${commonStyles.grid}`;
    }
  }
  function getMembersInGridView() {
    return (
      <div id="persons-grid" ref={viewRef} className={getStylesForMemberGrid()}>
        {data.elements.map((item, index) => (
          <div key={item.id} className={commonStyles.grid__item}>
            {getMemberView(item)}
          </div>
        ))}
      </div>
    );
  }
  function allItemsLoaded() {
    if (data.totalElements) {
      return parseInt(data.elements.length) === parseInt(data.totalElements);
    } else {
      return true;
    }
  }
  function getLowerPanelButtonsAndLoadedStatus() {
    return (
      <div className={styles.lowerButtonPanelWrapper}>
        <div className={styles.lowerButtonPanel}>
          <button
            className={
              allItemsLoaded()
                ? commonStyles.lightgrayButton
                : commonStyles.grayButton
            }
            type="button"
            id="loadMoreButton"
            onClick={loadMoreClicked}
            disabled={allItemsLoaded()}
          >
            {textFor("loadMore")}
          </button>
          <div className={commonStyles.currentlyShowingCount}>
            {data.elements.length}/
            {data.totalElements ? data.totalElements : data.elements.length}
          </div>
        </div>
      </div>
    );
  }
  function hidePersonDetailDialog() {
    setPersonToViewDetail(null);
    setDialogShow("");
  }

  function ifNeedsShowMemberDetailDialog() {
    if (shouldShowMemberDetailDialog) {
      return (
        <PersonDetailsDialog
          onClose={hidePersonDetailDialog}
          personDetails={personToViewDetail}
          getViewForTopRightArea={() =>
            getViewForTopRightArea(personToViewDetail)
          }
        />
      );
    } else {
      return "";
    }
  }
  return (
    <div className={styles.wrapper} id="persons-view">
      {ifNeedsShowMemberDetailDialog()}
      {getMembersInGridView()}
      {getLowerPanelButtonsAndLoadedStatus()}
    </div>
  );
}

export default FriendsInGridView;
