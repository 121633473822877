import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import store from "../store/index";
import { authActions } from "../store/reducers/authSlice";
import { Loader } from "../components/UI/ErrorModal";
import { doNavigate } from "../util/auth";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  Form,
  redirect,
  Link,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import {
  isAPhoneNumber,
  isAValidPassword,
  isAnEmail,
  isLikeAPhoneNumber,
  isLikeAnEmail,
} from "../util/fieldChecker";
import { BASE_URL, LOGIN_STATUS } from "../Constants";
import commonStyles from "../commonStyles.module.scss";
import { textFor } from "../util/languages";
import HelpTermsAndPrivacyPolicyView from "./HelpTermsAndPrivacyPolicyView";
function LoginPage() {
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  useEffect(() => {
    //TODO THIS SHOULD NOT BE REUIRED AS WE ARE ALREADY USING ROUTER LOADER
    if (isAuthenticated) {
      // Navigate to "/dashboard" route
      // Example with useNavigate from React Router
      doNavigate(navigate, "/dashboard");
    }
  }, [isAuthenticated, navigate]);

  // const [username, setUsername] = useState("at0001@b.com");
  // const [password, setPassword] = useState("welcome");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [eyeOn, setEyeOn] = useState("");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    localStorage.setItem("phoneOrEmail", e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className={commonStyles.pageBackground}>
      {isSubmitting && <Loader />}
      <h2 className={commonStyles.pageTitle}>{textFor("login")}</h2>
      <br />
      <br />
      <Form method="post">
        <div>
          <label className={commonStyles.fieldLabel} htmlFor="username">
            {textFor("phoneOrEmail:")}
          </label>
          <br />
          <input
            className={commonStyles.inputField}
            type="text"
            id="username"
            placeholder={textFor("emailPhonePlaceholder")}
            name="username"
            value={username}
            onChange={handleUsernameChange}
          />
        </div>
        <br />
        <div>
          <label className={commonStyles.fieldLabel} htmlFor="password">
            {textFor("password:")}
          </label>
          <br />
          <div>
            <input
              className={commonStyles.inputField}
              type={eyeOn === "" ? "password" : "text"}
              id="password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
            />
            <span
              id="eyeIcon"
              className={commonStyles.eyeIcon}
              onClick={() => {
                setEyeOn((prevState) => {
                  return prevState === "" ? "1" : "";
                });
              }}
            >
              {eyeOn === "" ? <FaEye /> : <FaEyeSlash />}
            </span>
          </div>
        </div>
        <br />
        <div>
          <button
            className={commonStyles.pageSubmitBtn}
            type="submit"
            disabled={isSubmitting}
          >
            {textFor("submit")}
          </button>
        </div>
      </Form>
      <div className={commonStyles.bottomSection}>
        <p>
          {textFor("forgotPassword?")}
          <Link
            style={{ color: "blue", marginLeft: "2vw" }}
            to="/forgotPassword"
          >
            {textFor("resetIt")}
          </Link>
        </p>
        <p style={{ color: "white" }}>
          {textFor("dontHaveAnAccount?")}
          <Link style={{ color: "blue", marginLeft: "2vw" }} to="/register">
            {textFor("registerButton")}
          </Link>
        </p>
        <br />
      </div>
      <HelpTermsAndPrivacyPolicyView />
      {/* <a href="tel:+918447899392">Call Me</a> */}
    </div>
  );
}
export default LoginPage;

export async function action({ request, params }) {
  console.log("login action is called.. request = ", request);
  const data = await request.formData();
  const password = data.get("password").trim();
  const username = data.get("username").trim();

  if (isLikeAPhoneNumber(username)) {
    if (!isAPhoneNumber(username)) {
      return null;
    }
  } else if (isLikeAnEmail(username)) {
    if (!isAnEmail(username)) {
      return null; //alert will be shown while checking in isAPhoneNumber() and isAnEmail()
    }
  }

  if (!isAValidPassword(password)) {
    return null;
  }
  let url = BASE_URL + "/token/login";
  let loginData = {
    username,
    password: password,
  };
  try {
    const response = await fetch(url, {
      method: request.method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(loginData),
    });

    const responseJson = await response.json();
    if (!response.ok) {
      if (responseJson.errorMessage.includes("Bad credentials")) {
        alert(textFor("badCredentials"));
      } else {
        alert(textFor("unknown_error"));
      }
      return "";
    }
    console.log("login response : ", responseJson);
    localStorage.setItem("token", responseJson.accessToken);
    store.dispatch(authActions.loginSuccess(responseJson.accessToken));
    localStorage.setItem(LOGIN_STATUS, "1");
    return redirect("/dashboard");
  } catch (e) {
    console.log("some error occurred: ", e);
    window.alert(textFor("unknown_error"));
  }
}
