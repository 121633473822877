import ReactDOM from "react-dom";
import { useState, useRef, useEffect } from "react";
import styles from "./SearchFilters.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import { BackDrop } from "../../components/UI/ErrorModal";
import { textFor } from "../../util/languages";
import { LANGUAGE_KEY } from "../../Constants";

function MultiColumnMultiSelectView({
  title,
  onClose,
  options,
  setFilterValue,
  formData,
  filterName,
}) {
  const parentRef = useRef(null);
  let initiallySelectedItems = new Set();
  if (formData[filterName] != "") {
    const array = formData[filterName].split(",").map((s) => s.trim());
    initiallySelectedItems = new Set(array);
  }

  const [itemStatus, setItemStatus] = useState(initiallySelectedItems);
  const [choiceVisible, setChoiceVisibility] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  function toggleItemStatus(value) {
    setScrollPosition(parentRef.current.scrollTop);
    setItemStatus((prevState) => {
      if (prevState.has(value)) {
        prevState.delete(value);
      } else {
        prevState.add(value);
      }
      return new Set(prevState);
    });
  }
  useEffect(() => {
    // Restore scroll position after the state update
    if (parentRef.current) {
      parentRef.current.scrollTop = scrollPosition;
    }
  }, [itemStatus, scrollPosition]); // Trigger effect when itemStatus changes
  function toggleChoicePanelVisibility(e) {
    setChoiceVisibility((prevState) => !prevState);
    console.log("multiSelect itemStatus = ", itemStatus);
    setFilterValue(filterName, [...itemStatus].join(", "));
  }
  const MultiColumnCheckboxes = () => {
    function getChooseFilterNameText() {
      if (filterName.toLowerCase().includes("gotra")) {
        return textFor("chooseGotra");
      } else if (filterName.toLowerCase().includes("dosha")) {
        return textFor("chooseKundliDosha");
      } else if (filterName.toLowerCase().includes("marital")) {
        return textFor("chooseMaritalStatus");
      }
      return "unkown";
    }
    const activeLanguage = localStorage.getItem(LANGUAGE_KEY) || "ENGLISH";
    return (
      <div className={styles.multicolumnCheckboxesWrapper}>
        <div className={styles.buttonPanel}>{getChooseFilterNameText()}</div>
        <div ref={parentRef} className={styles.matrixGrid}>
          {options.map((g) => (
            <div
              onClick={(e) => {
                e.stopPropagation();
                toggleItemStatus(g.id);
              }}
              key={g.id}
              className={styles.matrixGridItem}
            >
              {itemStatus.has(g.id) ? (
                <i
                  style={{ fontSize: "22px" }}
                  className="fa-regular fa-circle-check fa-1x"
                ></i>
              ) : (
                <i
                  style={{ fontSize: "20px" }}
                  className="fa-regular fa-circle fa-1x"
                ></i>
              )}
              <div>{activeLanguage === "ENGLISH" ? g.name : g.hindiName}</div>
              {/* <div className={styles.optionTextWrapper}>
                <div className={styles.optionText}>{g.name}</div>
              </div> */}
            </div>
          ))}
        </div>
        <div className={styles.buttonPanel}>
          <button
            style={{ padding: "5px 25px", margin: "2px 5px" }}
            className={commonStyles.orangeButton}
            onClick={toggleChoicePanelVisibility}
          >
            OK
          </button>
        </div>
      </div>
    );
  };
  function getMulticolumChoicesSelectionView() {
    return (
      <>
        {ReactDOM.createPortal(
          <BackDrop onConfirm={(e) => {}} />,
          document.getElementById("backdrop-root")
        )}
        {ReactDOM.createPortal(
          <MultiColumnCheckboxes />,
          document.getElementById("overlay-root")
        )}
      </>
    );
  }

  return (
    <>
      {choiceVisible && getMulticolumChoicesSelectionView()}
      <div className={styles.boxWithBorder}>
        <div className={styles.titleAndCloseButton}>
          <div className={styles.topLeftBoxTitle}>{title}</div>
          <div onClick={onClose} className={styles.topRightCloseBtn}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>

        <div className={styles.boxContent}>
          <div className={styles.choiceCountAndChooseButton}>
            <div>
              {" "}
              {textFor("selected:")}
              {itemStatus.size}{" "}
            </div>
            <button
              className={commonStyles.brownButton}
              onClick={toggleChoicePanelVisibility}
            >
              {textFor("choose")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default MultiColumnMultiSelectView;
